<template>
  <b-container>
    <div v-if="moduleId != 17">
      <validation-observer ref="form">
        <b-form-group class="my-2">
          <validation-provider rules="required" v-slot="{ errors, valid }">
            <b-form-textarea
              id="textarea"
              v-model="item.motive"
              placeholder="Justification..."
              rows="3"
              max-rows="6"
              :state="errors[0] ? false : valid ? true : null"
            ></b-form-textarea>
          </validation-provider>
        </b-form-group>
        <validation-provider rules="required" v-slot="{ errors }" name="status">
          <b-form-group class="my-2">
            <b-form-select
              id="status"
              v-model="item.status"
              :options="options"
              :state="errors[0] ? false : null"
            ></b-form-select>
          </b-form-group>
        </validation-provider>
      </validation-observer>
    </div>
    <div v-else>
      <validation-observer ref="form">
        <b-form-group class="my-2">
          <validation-provider
            rules="required"
            v-slot="{ errors, valid }"
            name="textarea"
          >
            <b-form-textarea
              id="textarea"
              disabled
              v-model="item.motive"
              placeholder="Justification..."
              rows="3"
              max-rows="6"
              :state="errors[0] ? false : valid ? true : null"
            ></b-form-textarea>
          </validation-provider>
        </b-form-group>
        <b-form-group class="my-2" v-if="moduleId == 17">
          <small>RRHH</small>
          <b-form-textarea
            id="textarea"
            v-model="item.commrrhh"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>
        <validation-provider rules="required" v-slot="{ errors }" name="status">
          <b-form-group class="my-2">
            <b-form-select
              id="status"
              name="status"
              v-model="justify.status"
              :options="options"
              :state="errors[0] ? false : null"
            ></b-form-select>
          </b-form-group>
        </validation-provider>
      </validation-observer>
    </div>
  </b-container>
</template>
<script>
import ScheduleService from "../services/schedules.service.js";
import { mapGetters } from "vuex";

export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    typeJustify() {
      return this.item.type == "abse" ? 2 : 1;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  mounted() {},
  data() {
    return {
      justify: {
        motive: null,
        status: null,
      },
      options: [
        { value: null, text: "Select an status" },
        { value: "0", text: "Disapprove" },
        { value: "1", text: "Approve" },
      ],
    };
  },
  methods: {
    sendForm() {
      if (this.moduleId != 17) {
        this.sendJustify();
      } else {
        this.sendJustifyRrhh();
      }
    },
    async sendJustify() {
      this.$refs.form.validate().then(async (confirm) => {
        if (!confirm) {
          return;
        }
        this.addPreloader();
        const params = {
          id: this.item.id,
          user_id: this.item.user_id,
          motive: this.item.motive,
          user_logged: this.currentUser.user_id,
          modul_id: this.currentUser.modul_id,
          date: this.item.date,
          status: this.item.status,
        };
        await ScheduleService.sendJustifyOvertime(params);
        this.removePreloader();
        this.closeModal();
      });
    },
    async sendJustifyRrhh() {
      this.$refs.form.validate().then(async (confirm) => {
        if (!confirm) {
          return;
        }
        this.addPreloader();
        const params = {
          id_user: this.item.user_id,
          user_super: this.currentUser.user_id,
          motive: this.item.motive,
          date: this.item.date,
          status: this.item.status,
          module_id: this.item.modul_id,
          rrhh: 1,
          comment: this.item.comment,
          cmmrrhh: this.item.commrrhh,
        };
        await ScheduleService.insertJustifyOvertime(params);
        this.removePreloader();
        this.closeModal();
      });
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>