<template>
  <b-container>
    <b-row>
      <b-table-simple>
        <b-thead>
          <b-tr class="text-center">
            <b-th scope="col">USER</b-th>
            <b-th scope="col">DATE</b-th>
            <b-th scope="col">DESCRIPTION</b-th>
            <b-th scope="col">ACTION</b-th>
          </b-tr>
        </b-thead>
        <b-tbody v-if="$route.meta.tag === 2">
          <b-tr class="text-center" v-if="tracking.type == 'late'">
            <b-th v-text="tracking.nameuser"></b-th>
            <b-td
              v-text="validateFecha(tracking)"
              v-if="tracking.type == 'late'"
            ></b-td>
            <b-td v-text="FormatDateTime(tracking.received)" v-else></b-td>
            <b-td v-text="tracking.motive"></b-td>
            <b-td>SEND</b-td>
          </b-tr>
          <b-tr class="text-center" v-if="tracking.module_id != 17">
            <b-th v-text="tracking.name_super"></b-th>
            <b-td v-text="FormatDateTime(tracking.received)"></b-td>
            <b-td v-text="tracking.cmm" v-if="tracking.type == 'late'"></b-td>
            <b-td v-else v-text="tracking.motive"></b-td>
            <b-td v-if="tracking.appv == 0">
              <span class="badge badge-danger" title="DISAPPROVED">
                <feather-icon icon="ThumbsDownIcon" />
                <!-- <i class="fas fa-thumbs-down"></i> -->
              </span>
            </b-td>
            <b-td v-else-if="tracking.type == 'abse'">SENT</b-td>
            <b-td v-else>REQUEST</b-td>
          </b-tr>

          <b-tr class="text-center" v-if="tracking.appvrrhh != null">
            <b-th v-text="validateUserRrhh(tracking.user_rrhh)"></b-th>
            <b-td v-text="FormatDateTime(tracking.date_review)"></b-td>
            <b-td v-text="tracking.cmmRrhh" v-if="tracking.modul_id != 17"
              >3</b-td
            >
            <b-td v-else>
              <p v-if="tracking.type == 'late'" v-text="tracking.cmm"></p>
              <p v-else v-text="tracking.motive"></p>
            </b-td>
            <b-td v-if="tracking.appvrrhh == 1">
              <span class="badge badge-success" title="APPROVED">
                <feather-icon icon="ThumbsUpIcon" />
                <!-- <i class="fas fa-thumbs-up"></i> -->
              </span>
            </b-td>
            <b-td v-else>
              <span class="badge badge-danger" title="DISAPPROVED">
                <feather-icon icon="ThumbsDownIcon" />
                <!-- <i class="fas fa-thumbs-down"></i> -->
              </span>
            </b-td>
          </b-tr>
        </b-tbody>
        <b-tbody v-else class="text-center">
          <b-tr v-if="tracking.modul_id != 17">
            <b-th
              v-text="
                tracking.usersuper ? tracking.usersuper : tracking.name_super
              "
            ></b-th>
            <b-td v-text="FormatDateTime(tracking.dateover)"></b-td>
            <b-td v-text="tracking.motive"></b-td>
            <b-td>SENT</b-td>
          </b-tr>
          <b-tr
            v-if="tracking.appv_overtime == 1 || tracking.appv_overtime == 0"
          >
            <b-th v-if="tracking.userrhh != null">{{ tracking.userrhh }}</b-th>
            <b-th v-else>Olenka Anorga</b-th>
            <b-td v-text="FormatDateTime(tracking.date_review_over)"></b-td>
            <b-td v-if="tracking.commrrhh" v-text="tracking.commrrhh"></b-td>
            <!-- <b-td
              v-if="tracking.overtimerrhhcm && tracking.motive == null"
              v-text="tracking.overtimerrhhcm"
            ></b-td> -->
            <b-td
              v-else-if="tracking.overtimerrhhcm == null && tracking.motive"
              v-text="tracking.motive"
            ></b-td>
            <b-td v-else>
              <span v-if="tracking.overtimerrhhcm && tracking.motive">
                {{ tracking.overtimerrhhcm }}
              </span>
              <span v-else>
                {{ tracking.motive }}
              </span>
            </b-td>
            <b-td>
              <span
                class="badge badge-success"
                title="APPROVED"
                v-if="tracking.appv_overtime == 1"
              >
                <feather-icon icon="ThumbsUpIcon" />
              </span>
              <span v-else class="badge badge-danger" title="DISAPPROVED">
                <feather-icon icon="ThumbsDownIcon" />
              </span>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-row>
  </b-container>
</template>
<script>
import moment from "moment";
export default {
  props: {
    tracking: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "user_name",
          label: "USER",
        },
        {
          key: "created_at",
          label: "DATE",
        },
        {
          key: "description",
          label: "DESCRIPTION",
        },
        {
          key: "status",
          label: "ACTION",
        },
      ],
    };
  },
  methods: {
    validateFecha(item) {
      if (item.created_at == null) {
        return moment(item.date).format("MM/DD/YYYY h:mm:ss");
      } else {
        return moment(item.created_at).format("MM/DD/YYYY");
      }
    },
    FormatFecha(fecha) {
      if (fecha == null) {
        return "--";
      } else {
        return moment(fecha).format("MM/DD/YYYY");
      }
    },
    FormatDateTime(dateTime) {
      if (dateTime == null) {
        return "--";
      } else {
        return moment(dateTime).format("MM/DD/YYYY h:mm:ss");
      }
    },
    validateUserRrhh(user_rrhh) {
      if (user_rrhh == null) {
        return "Daniela Rivera";
      } else {
        return user_rrhh;
      }
    },
  },
};
</script>
