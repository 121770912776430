var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[(_vm.moduleId != 17)?_c('div',[_c('validation-observer',{ref:"form"},[_c('b-form-group',{staticClass:"my-2"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-textarea',{attrs:{"id":"textarea","placeholder":"Justification...","rows":"3","max-rows":"6","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.item.motive),callback:function ($$v) {_vm.$set(_vm.item, "motive", $$v)},expression:"item.motive"}})]}}],null,false,3037491295)})],1),_c('validation-provider',{attrs:{"rules":"required","name":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"my-2"},[_c('b-form-select',{attrs:{"id":"status","options":_vm.options,"state":errors[0] ? false : null},model:{value:(_vm.item.status),callback:function ($$v) {_vm.$set(_vm.item, "status", $$v)},expression:"item.status"}})],1)]}}],null,false,2211711257)})],1)],1):_c('div',[_c('validation-observer',{ref:"form"},[_c('b-form-group',{staticClass:"my-2"},[_c('validation-provider',{attrs:{"rules":"required","name":"textarea"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-textarea',{attrs:{"id":"textarea","disabled":"","placeholder":"Justification...","rows":"3","max-rows":"6","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.item.motive),callback:function ($$v) {_vm.$set(_vm.item, "motive", $$v)},expression:"item.motive"}})]}}])})],1),(_vm.moduleId == 17)?_c('b-form-group',{staticClass:"my-2"},[_c('small',[_vm._v("RRHH")]),_c('b-form-textarea',{attrs:{"id":"textarea","rows":"3","max-rows":"6"},model:{value:(_vm.item.commrrhh),callback:function ($$v) {_vm.$set(_vm.item, "commrrhh", $$v)},expression:"item.commrrhh"}})],1):_vm._e(),_c('validation-provider',{attrs:{"rules":"required","name":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"my-2"},[_c('b-form-select',{attrs:{"id":"status","name":"status","options":_vm.options,"state":errors[0] ? false : null},model:{value:(_vm.justify.status),callback:function ($$v) {_vm.$set(_vm.justify, "status", $$v)},expression:"justify.status"}})],1)]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }